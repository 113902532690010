import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { nanoid } from "nanoid";
import DisplayDate from "./DisplayDate";
const slugify = require("slugify");
export default function DisplayRelated(props) {
  let { related } = props;

  // trim down to the first four results

  if (related) {
    if (related.length > 4) {
      related = related.slice(0, 4);
    }

    let authorSlugs = [],
      catSlugs = [];

    related.forEach((item) => {
      item = item.frontmatter;
      //console.log(item);
      authorSlugs[item.author] = `/author/${slugify(item.author, {
        lower: true,
        trim: true,
      })}`;

      catSlugs[item.author] = `/category/${slugify(item.category, {
        lower: true,
        trim: true,
      })}`;
    });

    return (
      <React.Fragment key={nanoid()}>
        <div className="columns is-multiline">
          {related.map((item) => {
            item.frontmatter.slug = item.fields.slug;
            item = item.frontmatter;

            return (
              <React.Fragment key={nanoid()}>
                <div className="column is-6">
                  <span>
                    <h3 className="title is-size-5 has-text-weight-bold is-bold-light mb-0">
                      <Link to={item.slug} title={item.title}>
                        {item.title ? item.title : item.name}
                      </Link>
                    </h3>

                    <h4 className="title is-size-6 has-text-weight-normal is-bold-light mt-2 mb-0">
                      In{" "}
                      <Link to={catSlugs[item.author]} title={item.category}>
                        {item.category}
                      </Link>{" "}
                      by{" "}
                      <Link to={authorSlugs[item.author]} title={item.author}>
                        {item.author}
                      </Link>{" "}
                      on <DisplayDate date={item.creationDate} />
                    </h4>
                  </span>
                  <br />
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
}

DisplayRelated.propTypes = {
  prev: PropTypes.object,
  next: PropTypes.object,
};
