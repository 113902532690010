import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import DisplayDate from "./DisplayDate";
const slugify = require("slugify");
export default function DisplayPrevNext(props) {
  let {
    previous = {},
    next = {},
    beginMessage = "This is our first entry.",
    endMessage = "This is our last entry.",
  } = props;
  beginMessage = beginMessage.trim();
  endMessage = endMessage.trim();

  if (beginMessage === "") beginMessage = false;
  let authorSlugs = [];
  let catSlugs = [];

  if (previous || next) {
    if (previous && previous.frontmatter && previous.frontmatter.author) {
      authorSlugs["previous"] = `/author/${slugify(
        previous.frontmatter.author,
        {
          lower: true,
          trim: true,
        }
      )}`;

      catSlugs["previous"] = `/category/${slugify(
        previous.frontmatter.category,
        {
          lower: true,
          trim: true,
        }
      )}`;
    }
    if (next && next.frontmatter && next.frontmatter.author) {
      authorSlugs["next"] = `/author/${slugify(next.frontmatter.author, {
        lower: true,
        trim: true,
      })}`;

      catSlugs["next"] = `/category/${slugify(next.frontmatter.category, {
        lower: true,
        trim: true,
      })}`;
    }

    return (
      <React.Fragment>
        <div className="content display-prev-next">
          <div className="columns">
            {previous ? (
              <div className="column">
                <span>
                  <h3 className="title is-size-5 has-text-weight-bold is-bold-light mb-0">
                    <Link
                      to={previous.fields.slug}
                      title={`Previous: ${previous.frontmatter.title}`}
                    >
                      {previous.frontmatter.title
                        ? previous.frontmatter.title
                        : previous.frontmatter.name}
                    </Link>
                  </h3>

                  <h4 className="title is-size-6 has-text-weight-normal is-bold-light mt-2 mb-0">
                    In{" "}
                    <Link
                      to={catSlugs["previous"]}
                      title={previous.frontmatter.category || null}
                    >
                      {previous.frontmatter.category}
                    </Link>{" "}
                    by{" "}
                    <Link
                      to={authorSlugs["previous"]}
                      title={previous.frontmatter.author || null}
                    >
                      {previous.frontmatter.author}
                    </Link>{" "}
                    on <DisplayDate date={previous.frontmatter.creationDate} />
                  </h4>
                </span>
              </div>
            ) : (
              <>
                {beginMessage ? (
                  <span>
                    <h6 className="title is-size-6 has-text-weight-normal is-bold-light">
                      {beginMessage}
                    </h6>
                  </span>
                ) : null}
              </>
            )}

            {next ? (
              <div className="column">
                <span>
                  <h3 className="title is-size-5 has-text-weight-bold is-bold-light mb-0">
                    <Link
                      to={next.fields.slug}
                      title={`Next: ${next.frontmatter.title}`}
                    >
                      {next.frontmatter.title
                        ? next.frontmatter.title
                        : next.frontmatter.name}
                    </Link>
                  </h3>

                  <h4 className="title is-size-6 has-text-weight-normal is-bold-light mt-2 mb-0">
                    In{" "}
                    <Link
                      to={catSlugs["next"]}
                      title={next.frontmatter.category || null}
                    >
                      {next.frontmatter.category}
                    </Link>{" "}
                    by{" "}
                    <Link
                      to={authorSlugs["next"]}
                      title={next.frontmatter.author || null}
                    >
                      {next.frontmatter.author}
                    </Link>{" "}
                    on <DisplayDate date={next.frontmatter.creationDate} />
                  </h4>
                </span>
              </div>
            ) : (
              <span>
                <h6 className="title is-size-6 has-text-weight-normal is-bold-light">
                  {endMessage}
                </h6>
              </span>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
}

DisplayPrevNext.propTypes = {
  prev: PropTypes.object,
  next: PropTypes.object,
};
