import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import DisplayPrevNext from "./DisplayPrevNext";
import DisplayRelated from "./DisplayRelated";

class DisplayKeepReadingRelatedTemplate extends React.Component {
  render() {
    const { data } = this.props;
    const { id } = this.props;
    let { related } = this.props;

    const { edges: posts } = data.allMarkdownRemark;

    let slugsUsed = [];

    let filteredPosts = posts.filter((posts) => posts.node.id === id);

    if (filteredPosts.length === 1) {
      let post = filteredPosts[0];
      let next = post.next;
      let previous = post.previous;

      if (next && next.fields) {
        slugsUsed.push(next.fields.slug);
      }
      if (previous && previous.fields) {
        slugsUsed.push(previous.fields.slug);
      }

      // filter out slugsUsed that
      if (slugsUsed.length) {
        slugsUsed.forEach((slug) => {
          related = related.filter((item) => item.fields.slug !== slug);
        });
      }
      //console.log(related);

      return (
        <>
          {next || previous ? (
            <>
              <div className="content keep-reading">
                <h4>Keep Reading:</h4>
                <DisplayPrevNext
                  previous={previous}
                  next={next}
                  beginMessage=""
                  endMessage=""
                />
              </div>
            </>
          ) : null}
          {related && related.length ? (
            <>
              <div className="content related-reading">
                <h4>Related Reading:</h4>
                <DisplayRelated related={related} />
              </div>
            </>
          ) : null}
        </>
      );
    } else {
      return null;
    }
  }
}

DisplayKeepReadingRelatedTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  count: PropTypes.number,
  id: PropTypes.string,
};
//frontmatter.related.mode == "plugin"
export default function DisplayKeepReadingRelated(arg) {
  return (
    <StaticQuery
      query={graphql`
        query DisplayKeepReadingRelatedQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___creationDate] }
            filter: {
              frontmatter: { advanced: { templateKey: { eq: "blog-post" } } }
            }
          ) {
            edges {
              node {
                id
              }
              next {
                fields {
                  slug
                }
                frontmatter {
                  title
                  creationDate
                  author
                  category
                }
              }
              previous {
                fields {
                  slug
                }
                frontmatter {
                  title
                  creationDate
                  author
                  category
                }
              }
            }
          }
        }
      `}
      render={(data, count, id) => (
        <DisplayKeepReadingRelatedTemplate
          data={data}
          count={count}
          id={arg.id}
          related={arg.related}
        />
      )}
    />
  );
}
